<template lang="pug">
div
  b-row.m-0.justify-content-center
    b-col.content-header(cols='9')
      h2.content-header-title.float-left.m-0.pr-1.mr-1
        | {{ $route.meta.pageTitle }}
      .content-header-search.mr-1
        h3.m-0 {{ poolForm.label }}
      .content-header-actions.d-flex
        feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
  .content-loader-center.m-0.h-100(v-if='isLoadingPool || isUpdatingPool || isCreatingPool')
    .text-center.flex-center
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des d&eacute;tails du bassin...
  b-row.content-scrollable-sticky.m-0.h-100.justify-content-center(v-else)
    b-col.content-scrollable-sticky-main(cols='9')
      validation-observer(ref='formCreateCustomer')
        b-row.my-0
            b-col.py-0.mb-1(cols='12')
              b-form-group(label='Client propriétaire du bassin *' label-for='customer')
                validation-provider(#default='{ errors }' name='customer' rules='required')
                  v-select#customer(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.customerId' label="firstName" :filter="searchCustomer" :options='customersList' @input="changePoolCustomer")
                    template(#option='{ firstName, lastName }')
                      | {{ firstName + ' ' + lastName }}
                    template(#selected-option='{ firstName, lastName }')
                      | {{ firstName + ' ' + lastName }}
                    template(v-slot:no-options='')
                      template
                        | Aucun r&eacute;sultat trouv&eacute;
                  small.text-danger {{ errors[0] }}
        b-row.my-0(v-if="poolForm.customerId && !poolForm.id")
          b-col.py-0(cols='12')
            b-form-checkbox(v-model='displayAddress' name='customerId' switch='')
              | L'adresse du bassin est différente de celle du client ?
        div(v-if="displayAddress || poolForm.id")
          vs-divider.mt-2.mb-1(color='#0096C7') Adresse du bassin
          b-row.my-0
            b-col.py-0(cols='12')
              validation-provider(#default='{ errors }' name='address' rules='required')
                b-form-group(label='Adresse *' label-for='width')
                  vue-typeahead-bootstrap#address.mb-1.not-autocomplete(type='text' :state='errors.length > 0 ? false : null' v-model='poolForm.address' :ieCloseFix='false' :data='autocompleteAddressData' :serializer='(item) => item.properties.label' @input='autocompleteAddressSource()' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                    template(slot='suggestion' slot-scope='{ data, htmlText }')
                      .item
                        small {{ data.properties.name }}
                        span
                          | {{ data.properties.postcode + ' ' + data.properties.city }}
          b-row.my-0
            b-col.py-0(cols='3')
              b-form-group(label='Code postal *' label-for='postalCode')
                validation-provider(#default='{ errors }' name='postalCode' rules='required')
                  b-form-input#postalCode(v-model='poolForm.postalCode' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                  small.text-danger {{ errors[0] }}
            b-col.py-0(cols='9')
              b-form-group(label='Ville *' label-for='city')
                validation-provider(#default='{ errors }' name='city' rules='required')
                  b-form-input#city(v-model='poolForm.city' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                  small.text-danger {{ errors[0] }}
          b-row.mt-1()
            b-col.py-0.mb-1(cols='6')
              b-form-group(label='Longitude' label-for='long')
                  b-form-input#long(v-model='poolForm.long' autocomplete='nope' aria-autocomplete='nope')
            b-col.py-0.mb-1(cols='6')
              b-form-group(label='Latitude' label-for='lat')
                  b-form-input#lat(v-model='poolForm.lat' autocomplete='nope' aria-autocomplete='nope')          
        vs-divider.mt-2.mb-1(color='#0096C7') Informations sur le bassin
        b-row.my-0
            b-col.py-0.mb-1(cols='12')
              b-form-group(label='Nom du bassin *' label-for='label *')
                validation-provider(#default='{ errors }' name='label' rules='required')
                  b-form-input#length(v-model='poolForm.label' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                  small.text-danger {{ errors[0] }}
        b-row.my-0
          b-col.py-0.mb-1(cols='3')
            b-form-group(label='Longueur (m) *' label-for='length')
              validation-provider(#default='{ errors }' name='length' rules='required')
                b-form-input#length(v-model='poolForm.length' type='number' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='3')
            b-form-group(label='Largeur (m) *' label-for='width')
              validation-provider(#default='{ errors }' name='width' rules='required')
                b-form-input#with(v-model='poolForm.width' type='number' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='3')
            b-form-group(label='Profondeur (cm) *' label-for='depth')
              validation-provider(#default='{ errors }' name='depth' rules='required')
                b-form-input#depth(v-model='poolForm.depth' type='number' :state='errors.length > 0 ? false : null' autocomplete='nope' aria-autocomplete='nope')
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='3')
            b-form-group(label='Volume (m³)' label-for='volume')
              b-form-input#volume(v-model='volume' type='number' :disabled="true" autocomplete='nope' aria-autocomplete='nope')
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Accès au bassin *' label-for='poolAccessType')
              validation-provider(#default='{ errors }' name='poolAccessType' rules='required')
                v-select#poolAccessType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.poolAccessType.id' :options='poolAccessTypesList')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label="Méthode d'accès *" label-for='accessMethodType')
              validation-provider(#default='{ errors }' name='accessMethodType' rules='required')
                v-select#accessMethodType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.accessMethodType.id' :options='accessMethodTypesList')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
                b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Etat *' label-for='condition')
              validation-provider(#default='{ errors }' name='condition' rules='required')
                v-select#customer(:state='errors.length > 0 ? false : null' :reduce='(type) => type.value' v-model='poolForm.condition' :options='optionConditions')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Végétation *' label-for='seaWeeds')
              validation-provider(#default='{ errors }' name='seaWeeds' rules='required')
                v-select#customer(:state='errors.length > 0 ? false : null' :reduce='(type) => type.value' v-model='poolForm.seaWeeds' :options='optionSeaWeeds')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
        vs-divider.mt-2.mb-1(color='#0096C7') Informations complémentaires
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-group(label='Indications du bassin' label-for='indications')
              vs-textarea(v-model='poolForm.indications' height='140px' style='min-height: 140px; font-size: 1rem')
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-group(label='Photos du bassin' label-for='pictures')
              vue-dropzone#pictures(ref='imgDropZonePool' @vdropzone-file-added="uploadFileAdded" :destroyDropzone="false" :options='dropzoneOptions' @vdropzone-removed-file="removeFile")
        vs-divider.mt-2.mb-1(color='#0096C7') Options
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Type de revêtement *' label-for='coatingType')
              validation-provider(#default='{ errors }' name='coatingType' rules='required')
                v-select#coatingType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.coatingType.id' :options='coatingTypesList')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Type de traitement *' label-for='waterTreatmentType')
              validation-provider(#default='{ errors }' name='waterTreatmentType' rules='required')
                v-select#waterTreatmentType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.waterTreatmentType.id' :options='waterTreatmentTypesList')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
        b-row.mb-1
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Type de bassin *' label-for='poolType')
              validation-provider(#default='{ errors }' name='poolType' rules='required')
                v-select#poolType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.poolType.id' :options='poolTypesList')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
          b-col.py-0.mb-1(cols='6')
            b-form-group(label='Type de contrat *' label-for='contractType')
              validation-provider(#default='{ errors }' name='contractType' rules='required')
                v-select#contractType(:state='errors.length > 0 ? false : null' :reduce='(type) => type.id' v-model='poolForm.contractType.id' :options='contractMaintenanceTypesList')
                  template(v-slot:no-options='')
                    template
                      | Aucun r&eacute;sultat trouv&eacute;
                small.text-danger {{ errors[0] }}
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasFenceWithGate' name='hasFenceWithGate' switch='')
                    | Présence de barrière avec porte ?
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasAlarmSystem' name='hasAlarmSystem' switch='')
                    | Présence d'un système d'alarme ?
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasAntiFrostSystem' name='hasAntiFrostSystem' switch='')
                  | Présence d'un système hors-gel ?
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasJetSwim' name='hasJetSwim' switch='')
                | Présence d'un système de nage contre courant ?
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasCleaningRobot' name='hasCleaningRobot' switch='')
              | Pr&eacute;sence d&apos;un robot ?
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasCurtain' name='hasCurtain' switch='')
              | Présence d'un rideau ?
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasHeatPump' name='hasHeatPump' switch='')
              | Pr&eacute;sence d'une pompe à chaleur ?
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasBufferTank' name='hasBufferTank' switch='')
              | Présence d'un bac tampon ?
        b-row.my-0
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasAutomaticFilling' name='hasAutomaticFilling' switch='')
              | Remplissage automatique ?
          b-col.py-0.mb-1(cols='6')
            b-form-checkbox(v-model='poolForm.hasWaterMeter' name='hasWaterMeter' switch='')
              | Présence d'un compteur d'eau ?
      b-row.my-0
        b-col.py-0(cols='12')
          .d-flex.justify-content-between.mt-2(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem')
            div
              b-button(variant='outline-primary' @click='cancel()')
                feather-icon(icon='ArrowLeftIcon')
                | Annuler
            b-button(variant='primary' @click='create')
              feather-icon(icon='SaveIcon' v-if='poolForm.id')
              feather-icon(icon='PlusIcon' v-else)
              | {{ poolForm.id ? "Modifier" : "Ajouter" }}
</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import { ValidationProvider, ValidationObserver, configure, localize } from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  name: "app",
  data() {
    return {
      displayAddress: false,
      displayGps: false,
      removedFile: false,
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      dropzoneOptions: {
        dictRemoveFileConfirmation: "Êtes-vous sûr de vouloir supprimer cette photo ? Cette action est irréversible.",
        autoQueue: false,
        url: "Testttt",
        maxFilesize: 10000000,
        thumbnailWidth: 300,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        dictRemoveFile: `<p class='m-0 p-0 text-default'>Supprimer</p>`,
        dictDefaultMessage: `<p class='text-default'>Faites glisser vos fichiers ou cliquez ici</p>`,
        manuallyAddFile: true,
        autoProcessQueue: false,
      },
      optionSeaWeeds: [
        {
          label: "Aucune",
          value: 1,
        },
        {
          label: "Modérée",
          value: 2,
        },
        {
          label: "Elevée",
          value: 3,
        },
      ],
      optionConditions: [
        {
          label: "Catastrophique",
          value: 1,
        },
        {
          label: "Mauvais état",
          value: 2,
        },
        {
          label: "État Moyen",
          value: 3,
        },
        {
          label: "Bon état",
          value: 4,
        },
        {
          label: "Impéccable",
          value: 5,
        },
      ],
      optionBinary: [
        {
          label: "Oui",
          value: true,
        },
        {
          label: "Non",
          value: false,
        },
      ],
      required,
      url,
      email,
      dataOrigine: {},
    };
  },
  async created() {
    this.popupPoolEvent(this.$route.params.id ? this.$route.params.id : "", this.$route.params.customerId ? this.$route.params.customerId : "");
    this.fetchCustomersList();
    this.fetchCoatingTypesList();
    this.fetchWaterTreatmentTypesList();
    this.fetchContractMaintenanceTypesList();
    this.fetchPoolTypesList();
    this.fetchPoolAccessTypesList();
    this.fetchAccessMethodTypesList();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    searchCustomer(options, search) {
      return options.filter((option) => {
        const fullName = (option.firstName + " " + option.lastName)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^\w\s]/gi, "")
          .trim()
          .toLowerCase();
        const searchNormalized = search
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^\w\s]/gi, "")
          .trim()
          .toLowerCase();
        if (fullName.includes(searchNormalized)) {
          return option;
        }
      });
    },
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    autocompleteAddressSource: debounce(function (indexSource) {
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${this.poolForm.address}&autocomplete=0&limit=50`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.poolForm.address = item.properties.name;
      this.poolForm.city = item.properties.city;
      this.poolForm.postalCode = item.properties.postcode;
      this.poolForm.lat = item.geometry.coordinates[1];
      this.poolForm.long = item.geometry.coordinates[0];
    },
    changePoolCustomer(event) {
      let poolCustomer = this.customersList.find((el) => el.id == event);
      this.poolForm.address = poolCustomer.address;
      this.poolForm.postalCode = poolCustomer.postalCode;
      this.poolForm.city = poolCustomer.city;
      this.poolForm.lat = poolCustomer.lat;
      this.poolForm.long = poolCustomer.long;
    },
    uploadFileAdded(file) {
      if (!file.id && this.poolForm.id) {
        let formData = new FormData();
        formData.append("file", file);
        this.uploadPoolFile({
          poolId: this.poolForm.id,
          label: file.name,
          file: formData,
        });
      }
    },
    downloadFileInDropZone() {
      this.poolForm.files.map((elem) => {
        const file = {
          size: elem.fileSize,
          name: elem.label,
          type: "image/png",
          id: elem.id,
        };
        const url = elem.fullPath;
        this.$refs.imgDropZonePool.manuallyAddFile(file, url);
      });
    },
    removeFile(file) {
      if (this.is_destroying) {
        return;
      }
      if (file.id) {
        this.deleteFile(file.id);
      }
    },
    popupPoolEvent(id, customerId) {
      if (id != "") {
        this.fetchPool(id).then((res) => {
          this.downloadFileInDropZone();
          this.poolForm = res;
          this.poolForm.poolAccessTypeId = res.poolAccessType.id;
          this.poolForm.poolTypeId = res.poolType.id;
          this.poolForm.waterTreatmentTypeId = res.waterTreatmentType.id;
          this.poolForm.accessMethodTypeId = res.accessMethodType.id;
          this.poolForm.coatingTypeId = res.coatingType.id;
          this.poolForm.contractTypeId = res.contractType.id;
          this.dataOrigine = JSON.stringify(this.poolForm);
        });
      } else {
        this.initializeForm();
        if (customerId) {
          this.fetchCustomer(customerId).then((res) => {
            this.poolForm.customerId = customerId;
            this.poolForm.lat = res.lat;
            this.poolForm.long = res.long;
            this.poolForm.city = res.city;
            this.poolForm.postalCode = res.postalCode;
            this.poolForm.address = res.address;
            this.dataOrigine = JSON.stringify(this.poolForm);
          });
        }
      }
    },
    initializeForm() {
      this.poolForm = {
        id: 0,
        label: "",
        indications: "",
        length: null,
        width: null,
        depth: null,
        hasAlarmSystem: false,
        condition: "",
        seaWeeds: "",
        hasFenceWithGate: false,
        hasCurtain: false,
        hasJetSwim: false,
        hasCleaningRobot: false,
        hasAntiFrostSystem: false,
        address: "",
        city: "",
        postalCode: "",
        long: null,
        lat: null,
        coatingType: { id: null },
        poolType: { id: null },
        poolAccessType: { id: null },
        accessMethodType: { id: null },
        contractType: { id: null },
        waterTreatmentType: { id: null },
        customerId: null,
        files: [],
      };
      this.dataOrigine = JSON.stringify(this.poolForm);
    },
    ...mapActions([
      "createPool",
      "updatePool",
      "fetchCustomer",
      "archiveCustomer",
      "fetchCustomersList",
      "fetchCoatingTypesList",
      "fetchContractMaintenanceTypesList",
      "fetchPoolTypesList",
      "fetchWaterTreatmentTypesList",
      "fetchPoolAccessTypesList",
      "fetchAccessMethodTypesList",
      "fetchPool",
      "uploadPoolFile",
      "deleteFile",
    ]),
    create(e) {
      e.preventDefault();
      this.$refs.formCreateCustomer
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.poolForm.id) {
              this.updatePool(this.poolForm).then(() => {
                if (this.$route.params.routeOrigine) {
                  this.$tabs.close({
                    to: "/" + this.$route.params.routeOrigine,
                  });
                } else {
                  this.$tabs.close({ to: "/pools" });
                }
              });
            } else {
              this.createPool(this.poolForm).then((res) => {
                Promise.all(this.uploadFiles(res.id)).then(() => {
                  if (this.$route.params.routeOrigine) {
                    this.$tabs.close({
                      to: "/" + this.$route.params.routeOrigine,
                    });
                  } else {
                    this.$tabs.close({ to: "/pools" });
                  }
                });
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    uploadFiles(id) {
      const files = this.$refs.imgDropZonePool.getAcceptedFiles();
      const allPromises = [];
      files.forEach((file) => {
        let formData = new FormData();
        formData.append("file", file);
        allPromises.push(this.uploadPoolFile({ poolId: id, label: file.name, file: formData }));
      });
      return allPromises;
    },
    archiveCustomerLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.", {
          title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveCustomer(id);
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },

    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.poolForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title: "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
  computed: {
    poolForm: {
      get() {
        return this.$store.getters.pool;
      },
      set(value) {
        return this.$store.commit("SET_POOL", value);
      },
    },
    volume() {
      switch (this.poolForm?.poolType?.shape) {
        case "rectangulaire":
          return Math.ceil(this.poolForm?.width * this.poolForm?.length * (this.poolForm?.depth / 100));
        case "ronde":
          var radius = this.poolForm?.width / 2;
          return Math.ceil(Math.PI * Math.pow(radius, 2) * (this.poolForm?.depth / 100));
        case "ovale":
          var semiMajorAxis = this.poolForm?.width / 2;
          var semiMinorAxis = this.poolForm?.length / 2;
          return Math.ceil(Math.PI * semiMajorAxis * semiMinorAxis * (this.poolForm?.depth / 100));
          default: 
            return null
      }
    },
    ...mapGetters([
      "isLoadingPool",
      "isUpdatingPool",
      "isCreatingPool",
      "customersList",
      "coatingTypesList",
      "contractMaintenanceTypesList",
      "poolTypesList",
      "waterTreatmentTypesList",
      "poolAccessTypesList",
      "accessMethodTypesList",
      "pool",
    ]),
  },
  watch: {
    removedFile() {
      let that = this;
      setTimeout(function () {
        that.removedFile = false;
      }, 2000);
    },
    "poolForm.coatingType.id": function (newValue) {
      this.poolForm.coatingTypeId = newValue;
    },
    "poolForm.waterTreatmentType.id": function (newValue) {
      this.poolForm.waterTreatmentTypeId = newValue;
    },
    "poolForm.poolType.id": function (newValue) {
      this.poolForm.poolTypeId = newValue;
    },
    "poolForm.contractType.id": function (newValue) {
      this.poolForm.contractTypeId = newValue;
    },
    "poolForm.poolAccessType.id": function (newValue) {
      this.poolForm.poolAccessTypeId = newValue;
    },
    "poolForm.accessMethodType.id": function (newValue) {
      this.poolForm.accessMethodTypeId = newValue;
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    VSwatches,
    Autocomplete,
    vueDropzone: vue2Dropzone,
    VueTypeaheadBootstrap,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
.dz-progress {
  display: none;
}
.content-scrollable-sticky {
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
